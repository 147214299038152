<template>
	<div>
		<div class="call-card" :class="{ active }">
			<router-link
				:to="{ name: 'app.calls.show', params: { sid: call.sid } }"
			>
				<div class="call-card__patient-details">
					<div class="call-card__title">
						<div class="call-card__icon-container">
							<div
								v-if="isComplete"
								class="complete-call"
								role="button"
								data-tooltip="Complete"
								data-position="right"
								@click.stop="onChipStatusClick('complete')"
							>
								<font-awesome-icon :icon="['fas', 'check']" />
							</div>
							<div
								v-else-if="isCallDismissed"
								class="dismissed-call"
								role="button"
								data-tooltip="Dismissed"
								data-position="right"
								@click.stop="onChipStatusClick('dismissed')"
							>
								<font-awesome-icon :icon="['far', 'circle']" />
							</div>
							<div
								v-else
								class="new-call"
								role="button"
								data-tooltip="New"
								data-position="right"
								@click.stop="onChipStatusClick('incomplete')"
							>
								<font-awesome-icon :icon="['fas', 'circle']" />
							</div>
						</div>
						<div class="call-card__title-container">
							<div
								v-if="call.patient_name"
								class="call-card-title call-card__title"
							>
								{{ call.patient_name }}
							</div>
							<div
								class="card-phone"
								:class="
									call.patient_name
										? 'call-card__phone'
										: 'call-card__title'
								"
							>
								{{ phoneNumber | newFormatPhoneNumber }}
							</div>
							<div
								v-if="isMRNAvialiable && isEmrConfigured"
								class="call-card__MRN"
							>
								{{ isMRNAvialiable }}
							</div>
						</div>
						<div class="call-card__icon-container">
							<div
								v-if="isAudioOnly"
								class="audio-only-call"
								data-tooltip="Audio Only"
								data-position="left"
							>
								<font-awesome-icon
									fixed-width
									:icon="['fas', 'volume']"
								/>
							</div>
						</div>
					</div>
				</div>
			</router-link>
			<div class="call-card__tag-time-details">
				<div class="call-card__tag-container">
					<chip
						v-if="call.is_urgent"
						condensed
						class="bg-orange-100 text-orange-500 border-orange-200 hover:bg-orange-200 hover:text-orange-600"
						@click.stop="onUrgentClick"
					>
						Urgent
					</chip>
					<chip v-else condensed @click.stop="onNonUrgentClick">
						Non-Urgent
					</chip>
					<chip v-if="hasFallback" condensed>
						Fallback
					</chip>
					<chip v-if="isRsmCall" condensed>
						Voicemail
					</chip>
					<chip
						v-for="type in call.types"
						:key="type.id"
						condensed
						@click.stop="onChipTypeClick(type)"
					>
						{{ type.name }}
					</chip>
					<chip
						v-if="hasNotes"
						condensed
						@click.stop="onChipStatusClick('noted')"
					>
						Notes
					</chip>
				</div>
				<div class="call-card__time-container">
					<span>{{ call.created_at | toTimestamp }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Chip from './Chip'
import { mapActions } from 'vuex'
import _get from 'lodash/get'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Chip,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the patient's phone number from the current call.
		 *
		 * @return {String}
		 */
		phoneNumber() {
			const number = _get(this, 'call.callback_number', null)
			if (!number || number.toLowerCase() === 'anonymous') {
				return null
			}
			return number
		},

		/**
		 * To acess if the partner is EMR configured or not.
		 *
		 */
		isEmrConfigured() {
			const emrSettings = _get(
				this.$store.getters,
				'partner.emr_settings',
				null
			)
			return !!emrSettings
		},

		/**
		 * To acess if MRN is avail or not.
		 *
		 */
		isMRNAvialiable() {
			const mrn = _get(this, 'call.call_patient_details.mrn', null)
			return mrn && mrn.toLowerCase() !== 'multiple' && mrn.trim() !== ''
				? `MRN ${mrn}`
				: null
		},

		/**
		 * Determine if the call is the current active call being displayed.
		 *
		 * @return {Boolean}
		 */
		active() {
			return this.call.sid === this.$route.params.sid
		},

		/**
		 * Determine if the current call has fallback notifications.
		 *
		 * @return {Boolean}
		 */
		hasFallback() {
			if (!this.call || !Array.isArray(this.call.events)) {
				return false
			}

			return this.call.events.some(event => {
				return (
					event.type === 'fallback-notification' ||
					event.summary === 'Fallback notification sent'
				)
			})
		},

		/**
		 * Determine if the current call is an RSM call.
		 *
		 * @return {Boolean}
		 */
		isRsmCall() {
			if (
				this.call &&
				this.call.sid &&
				typeof this.call.sid === 'string' &&
				this.call.sid.startsWith('RSM')
			) {
				return true
			} else {
				return false
			}
		},

		/**
		 * Determine if the call has any attached notes.
		 *
		 * @return {Boolean}
		 */
		hasNotes() {
			return this.call.notes && this.call.notes.length > 0
		},

		/**
		 * Determine if the audio only chip should be displayed.
		 *
		 * @return {Boolean}
		 */
		isAudioOnly() {
			return !this.call.patient_name && !this.call.patient_dob
		},

		/**
		 * Determine if the current call is complete.
		 *
		 * @return {Boolean}
		 */
		isComplete() {
			return !!this.call.completed_at
		},

		/**
		 * Determine if the current call is dismissed.
		 *
		 * @return {Boolean}
		 */
		isCallDismissed() {
			return !!this.call.dismissed_by
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle a filter chip being clicked.
		 *
		 * @param {String} status
		 * @return {void}
		 */
		onChipStatusClick(status) {
			const { statuses } = this.$store.state.calls.filters

			if (!statuses.includes(status)) {
				this.applyFilters({ statuses: [...statuses, status] })

				this.fetchCalls()
			}
		},

		/**
		 * Handle the call type chip click event.
		 *
		 * @param {Object} type
		 * @return {void}
		 */
		onChipTypeClick({ id, name }) {
			const { types } = this.$store.state.calls.filters

			const exists = types.findIndex(type => type.id === id) > -1

			if (!exists) {
				this.applyFilters({ types: [{ id, name }] })

				this.fetchCalls()
			}
		},

		/**
		 * Handle the non-urgent chip click event.
		 *
		 * @return {void}
		 */
		onNonUrgentClick() {
			const { nonUrgent } = this.$store.state.calls.filters

			if (!nonUrgent) {
				this.setNonUrgentFilter(true)

				this.fetchCalls()
			}
		},

		/**
		 * Handle the urgent click event.
		 *
		 * @return {void}
		 */
		onUrgentClick() {
			const { urgent } = this.$store.state.calls.filters

			if (!urgent) {
				this.setUrgentFilter(true)

				this.fetchCalls()
			}
		},

		...mapActions('calls', {
			fetchCalls: 'get',
			applyFilters: 'setFilters',
			setUrgentFilter: 'setUrgentFilter',
			setNonUrgentFilter: 'setNonUrgentFilter',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'InboxCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The call to be displayed.
		 *
		 * @type {Object}
		 */
		call: {
			type: Object,
			required: true,
		},
	},
}
</script>
